<template>
    <b-row>
      <!-- Small -->
  
      <!-- Large -->
      <b-col cols="12">
        <b-form-group>
          <v-select
            v-if="items != null"
            v-model="selected"
            chips
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="items"
            class="select-size-lg"
            filled
          />
        </b-form-group>
      </b-col>
    </b-row>
  </template>
  
  <script>
  import { BRow, BCol, BFormGroup } from 'bootstrap-vue';
  import vSelect from 'vue-select';
  import store from '@/store';
  import Ripple from 'vue-ripple-directive';
  
  // import useUsersList from '../courseCategoryStoreModule';
  // import axios from '@axios';
  import { getCall } from '../../Service';
  export default {
  
    components: {
      BRow,
      BCol,
      BFormGroup,
      vSelect,
    },
    directives: {
      Ripple,
    },
    
    props: {
      initialCategories: [],
      categories:[],
      selected: '',
    },
    mounted(){
        this.getAllBrands()
    },
    updated() {
        //console.log('updatedBrand',this.selected);
    },
    methods: {
      updateSelections(a) {
        //console.log(a);
      },
      async getAllBrands() {
        try {
          const { data } = await getCall('admin/store/brand/all');
          this.items = data.brands.map(i=>{
            return {
            id:i.id,
            name:i.title,
          }
          })
          this.selected = this.items.filter(i=>i.id==this.selected)[0]

        } catch (error) {
          if (error.response) {
            Swal.fire('Error', error.response.data.message, 'error');
          } else {
            Swal.fire('Error', error, 'error');
          }
        } finally {
     
        }
      },
     
      // },
    },
    watch: {
      selected: function (newVal, coldVal) {
        //console.log(this.initialCategories);
        //console.log(this.selected);
        //console.log('added', newVal);
        // const temp = newVal.map((i) => i.id);
        
        this.$emit('selectionChanged', newVal);
      },
    },
  
    data() {
      return {
        categories: null,
        selected: '',
        items:[],
      };
    },
  };
  </script>
  